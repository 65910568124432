













import { Watch, Component, Vue } from "vue-property-decorator";
import { FileManager } from "uknow-react-components";
import TopBar from "@/components/common/TopBar.vue";
import store from "../../store";
import mainAuth from "@/auth";
import { getFolderGuid } from "../../helper/utils";

@Component({
  components: { TopBar, FileManager },
})
export default class TokenDocs extends Vue {
  get accessToken() {
    return mainAuth().accessToken;
  }

  get vourusUrl() {
    return store.state.vourusUrl;
  }

  get selectedClient() {
    return store.getters.selectedClient;
  }

  get folderGuid() {
    return store.getters.folderGuid;
  }

  mounted() {
    this.reloadFolderGuid();
  }

  @Watch("selectedClient")
  updateSelectedClient(val: any, oldVal: any) {
    this.reloadFolderGuid();
  }

  reloadFolderGuid() {
    getFolderGuid();
  }
}
